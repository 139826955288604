// Here you can add other styles
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
$primary-color: #E21515;
$secondary-color: #353540;
$text-btn: #0994ff;

body {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    color: $secondary-color;
    background: #f7f7f7;
}


h1 {
    font-size: 28px;
}

h2 {
    font-size: 24px;
}

h3 {
    font-size: 18px;
    text-transform: capitalize;
}

h4 {
    font-size: 22px;
}

h5 {
    font-size: 16px;
}

h6 {
    font-size: 14px;
}

.form-control {
    border: solid 1px #D9D9D9;
    background: #fff;
    font-size: 14px;
}

.form-label {
    opacity: 0.7;
}

.form-control:focus {
    background: #fff;
}
button {
    &.download-btn {
        background: #56565e;
        border-radius: 4px;
        line-height: 1;
        color: #ffffff;
        border-color: #56565e;
        box-shadow: none;
        padding: 3px 7px;
        
        &:hover {
            background: #56565e;
            border-color: #56565e;
            color: #ffffff;
        }
    }
}

.btn-primary {
    align-items: center;
    background-color: $primary-color;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-weight: 500;
    justify-content: center;
    line-height: 1.25;
    margin: 0;
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    width: auto;
    font-size: 14px;
    padding: 8px 24px;
    min-width: 70px;
  
    &:hover {
      border-color: rgba(0, 0, 0, 0.15);
      box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
      color: $white;
      // transform: translateY(-1px);
    }
  
    &:focus {
      border-color: rgba(0, 0, 0, 0.15);
      box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
      color: $white;
    }
  
    &:active {
      background-color: $white;
      border-color: rgba(0, 0, 0, 0.15);
      box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
      color: $white !important;
    }
  }
  
  .btn-light {
    align-items: center;
    background-color: $white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    display: inline-flex;
    font-weight: 500;
    justify-content: center;
    line-height: 1.25;
    margin: 0;
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    width: auto;
    font-size: 14px;
    padding: 8px 24px;
    min-width: 70px;
  
    &:hover {
      border-color: rgba(0, 0, 0, 0.15);
      box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
      color: rgba(0, 0, 0, 0.65);
      // transform: translateY(-1px);
      background: #f3f3f3;
    }
  
    &:focus {
      border-color: rgba(0, 0, 0, 0.15);
      box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
      color: rgba(0, 0, 0, 0.65);
    }
  
    &:active {
      background-color: $white;
      border-color: rgba(0, 0, 0, 0.15);
      box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
      color: rgba(0, 0, 0, 0.65);
      transform: translateY(0);
    }
  }

  img.logotop {
    width: 110px;
}
.form-check {
    margin-bottom: 15px;
}

label.form-check-label {
    padding: 0px 8px;
    cursor: pointer;
}

/* login section */
.loginSection {
    .card {
        max-width: 80%;
        border-radius: 10px;
        margin: 0px auto;


        h3 {
            margin: 30px 0px;
            font-size: 20px;
            text-align: center;
        }

        .form-control {
            margin-bottom: 15px !important;
        }

        .remember {
            padding: 10px 0px;
        }

        .form-check-input {
            height: 24px;
            width: 24px;
        }

        .btn-primary {
            padding: 10px;

            &:hover {
                color: #fff;
                -webkit-box-shadow: -1px 1px 6px -2px rgba(0, 0, 0, 0.6);
                -moz-box-shadow: -1px 1px 6px -2px rgba(0, 0, 0, 0.6);
                box-shadow: -1px 1px 6px -2px rgba(0, 0, 0, 0.6);
            }
        }

        .forgotPassword {
            button {
                color: $secondary-color;
                text-decoration: none;

                &:hover {
                    color: $primary-color;
                    text-decoration: underline;
                }
            }
        }

        .forgot {
            color: #7070707d;
        }
    }
}

.form-check-input:focus {
    box-shadow: none;
}

.form-control:focus {
    box-shadow: none;
}

/* side bar top header */
.sidebar {
    img {
        width: 50%;
    }

}

.avatar-img {
    width: 80%;
}

/* side bar navbar */
.sidebar-nav {
    padding: 8px;

    .nav-item {
        margin-bottom: 4px;

        .nav-link {
            display: inherit;
            color: #fff;
            margin: 0px auto;
            padding: 10px 25px;
            border-radius: 4px;
            font-weight: 300;

            &:hover {
                background: $primary-color;
                // background: linear-gradient(181deg, #ffffff00, #e21515);
                background-color: #e21515
            }
        }

        a {
            svg {
                margin-right: 12px;
            }
        }
    }


}

a.nav-link.active {
    // background: linear-gradient(181deg, #ffffff00, #e21515) !important;
    background-color: #e21515 !important;
}






/* card header */






/* offcanvas css */
.leftOuterBox {
    position: relative;

    h5 {
        margin-bottom: 15px;
        font-size: 18px;
    }

    .arrowMoveRgiht {
        position: absolute;
        top: 55%;
        right: -15px;

        button {
            background: no-repeat;
            border: 0;
            padding: 0;
        }

    }

}

.rightOuterBox {
    position: relative;

    h5 {
        margin-bottom: 15px;
        font-size: 18px;
    }

    .arrowMoveLeft {
        position: absolute;
        top: 45%;
        left: -56px;

        button {
            background: no-repeat;
            border: 0;
            padding: 0;
        }

    }

}

.leftInputBox {
    height: 60vh;
    overflow: hidden;
    overflow-y: scroll;


}

.rightInputBox {
    height: 60vh;
    overflow: hidden;
    overflow-y: scroll;
    position: relative;


}



.OffCanvasCtm input[type="checkbox"] {
    width: 18px;
    height: 18px;
    vertical-align: bottom;
    margin-right: 6px;
}

.checkBoxHeader {
    margin-bottom: 20px;
}

.checkBoxRow {
    margin-bottom: 15px;
}


/* offcanvas-sidebar */
.offcanvas {
    -webkit-border-top-left-radius: 4px;
    -webkit-border-bottom-left-radius: 4px;
    -moz-border-radius-topleft: 4px;
    -moz-border-radius-bottomleft: 4px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  
    table {
      thead {
        display: table-header-group;
      }
  
      tbody {
        display: table-row-group;
      }
    }
  
    .offcanvas-header {
      background: #d9d9d9;
      -webkit-border-top-left-radius: 4px;
      -moz-border-radius-topleft: 4px;
      border-top-left-radius: 4px;
      padding: 10px 20px 10px 10px;
  
      h5 {
        font-weight: 500;
      }
    }
}
  

/* card body right section */
.cardBody {
    .card {
        border-radius: 4px;
        background: none;
     border: 0;

        .card-body {
            padding: 0;

            .cardHeader {
                display: flex;
                flex-shrink: 0;
                align-items: center;
                justify-content: space-between;
              padding: 10px 0;

                .btn-primary {
                    padding: 5px 20px;
                    font-size: 14px;
                    background-color: transparent;
                    border: solid 1px #CDD4D9;
                    color: #353540;
                    border-radius: 60px;
                    font-weight: 500;
                    letter-spacing: 0;

                    &:hover {
                        background: #FFFFFF;
                        background: linear-gradient(180deg, #FFFFFF, #f4bdbd);
                        border: solid 1px #f4bdbd;
                    }
                }

                .mr-1 {
                    margin-right: 8px;
                }

                .editIcon{
                    background: no-repeat;
                    border: 0;
                    margin-right: 10px;
                    img{
                        width: 16px; 
                    }
                }

            }
        }

    }


}

.cardOffCan{
.offcanvas-body{
    .card-body{
        padding: 0;
    }
}
}


button.page-link {
    padding: 4px 12px;
}

/* table css */

.cardTable{
    table {
        tr{
            th{
                background: #EAF0F2;
                font-weight: 500;
                
            }
            td{
                font-size: 13px;
                .btn-primary{
                    font-size: 11px;
                    background: #fff;
                    border: solid 1px #0994FF;
                    border-radius: 60px;
                    color: #0994FF;
                    margin-right: 10px;
                    &:hover{
                        background: #0994FF;
                        border: solid 1px #0994FF;
                        color: #ffffff;
                    }
                }

                .editIcon{
                    background: no-repeat;
                    border: 0;
                    margin-right: 10px;
                    img{
                        width: 16px; 
                    }
                }
                .deleteIcon{
                    background: no-repeat;
                    border: 0;
                    img{
                        width: 14px; 
                    }
                }

            }
        }
    }

}

/* offcanvas-body */


// Header CSS START

.header-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
}
.sidebar.sidebar-fixed {
    top: 66px;
    padding-top: 10px;
}
.sidebar:not(.sidebar-end) ~ * {
    padding-top: 66px;
}


@media only screen and (min-width: 768px) {
    html {
        &:not([dir=rtl]) {
            .wrapper {
                &.sidebar-closed {
                    margin-left: 70px !important;
                    width: calc(100% - 70px) !important;
                }
            } 
            .sidebar {
                &.hide {
                    &:not(.sidebar-end) {
                        margin-left: 0;
                        --cui-sidebar-width: 70px;
                        .nav-item {
                            .nav-link {
                                font-size: 0;
                                transition: all 0.3s ease-in-out;
                                min-height: 42.5px;
                                svg {
                                    margin-left: -7px;
                                }
                            }
                        }
                    }
                }
            }
        }
    } 
}
@media only screen and (max-width: 767px) {
    html {
        &:not([dir=rtl]) {
            .wrapper {
                &.sidebar-open {
                    margin-left: 0px !important;
                    width: 100%  !important;
                }
            } 
        }
    }
}
// Header CSS END



/* responsive media quires */
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    body {
        font-size: 14px;
    }

    .sidebar-nav {
        .nav-item {
            .nav-link {
                font-size: 14px;

            }
        }
    }

    .cardBody {
        .card {
            .card-body {
                .cardHeader {
                    .btn-primary {
                        padding: 4px 16px;
                        font-size: 12px;
                    }
                    input[type="text"] {
                        border: solid 1px #CDD4D9;
                        padding: 4px 16px;
                        border-radius: 60px;
                        margin-right: 10px;
                        &:focus{
                            border: solid 1px #CDD4D9;
                        }
                        &:hover{
                            border: solid 1px #CDD4D9;
                        }
                    }
                  
                }

            }
        }

    }
}
.cardHeader{
    input[type="text"] {
        border: solid 1px #CDD4D9;
        padding: 4px 16px;
        border-radius: 60px;
        margin-right: 10px;
        &:focus{
            border: solid 1px #CDD4D9;
        }
        &:hover{
            border: solid 1px #CDD4D9;
        }
    }
}

// tooltip more
.menuiconHover {
	// justify-content: space-between;
	justify-content: left;
	gap: 7px;

	.MuiDataGrid-root {
		font-family: "Poppins", sans-serif !important;
		font-size: 12px !important;
	}

	.card {
		padding: 10px;
		min-width: 80px;
		max-width: 80px;
		display: flex;
		align-items: center;
		justify-items: center;
		font-size: 12px;
		background: linear-gradient(180deg, #FFFFFF, #f9f9f9);
		border: solid 1px #e1e1e1;
		margin-bottom: 10px;

		&:hover {
			background: linear-gradient(180deg, #FFFFFF, #fbf3f3);
			border: solid 1px #e1e1e1;
			cursor: pointer;
		}

		.card-title {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: inline-block;
			width: 70px;
			text-align: center;
		}


		img {
			margin-bottom: 6px;
			width: 30px;
		}

	}

}
label.form-check-label {
    cursor: pointer;
    line-height: 0;
    vertical-align: middle;
    padding-left: 8px;
  }
.box {
    border: 1px solid rgb(204, 204, 204);
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  
    .form-check {
      margin: 0px;
      margin-bottom: 6px;
      vertical-align: super;
      justify-content: normal;
  
      input {
        margin-top: 3px;
      }
  
      label {
        padding-left: 2px;
        vertical-align: middle;
      }
    }
  
    h6 {
      color: $secondary-color;
      font-size: 15px;
      padding: 10px 0px 10px 0px;
      font-weight: 500;
    }
  }

.CheckBoxAbn {
    position: relative;
  
    .CheckIcon {
      position: absolute;
      top: 37px;
      right: 10px;
      cursor: pointer;
    }
}

.dataGridHeader {
    background-color: #eaf0f2;
  }

  .datatableGrid {
    height: calc(100vh - 120px);
    width: 100%;
  
    .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.MuiDataGrid-columnHeaders {
      background: #eaf0f2 !important;
      font-size: 14px;
      font-weight: 600;
    }
  
    .MuiDataGrid-root .MuiDataGrid-container--top [role="row"],
    .MuiDataGrid-root .MuiDataGrid-container--bottom [role="row"] {
      background: #eaf0f2;
    }
  
    button {
      background: transparent;
      // padding: 4px 8px;
      border: 0;
      margin-right: 5px;
      color: #707070;
      font-size: 24px;
  
      img {
        background: none;
        border: 0;
        width: 24px;
        margin-right: 10px;
      }
    }
  
    .btn-primary {
      font-size: 12px;
      background: $white;
      border: solid 1px $text-btn;
      border-radius: 60px;
      color: $text-btn;
      margin-right: 0;
      padding: 4px 10px;
  
      &:hover {
        background: $text-btn;
        border: solid 1px $text-btn;
        color: #ffffff;
        box-shadow: none;
        padding: 4px 10px;
        transform: translateY(0px);
      }
    }
    
    .disabled {
      font-size: 12px;
      background: none;
      border: solid 1px #898989;
      border-radius: 60px;
      color: #898989;
      margin-right: 0;
      padding: 6px 12px;
    }
  }

  .rowIcon {
    vertical-align: middle;
    display: flex;
  
    .download {
      cursor: pointer;
      color: #0994ff;
    }
  
    .view {
      cursor: pointer;
      color: #707070;
    }
  }

  // data table grid
.rowIcon {
    vertical-align: middle;
    display: flex;
  
    .download {
      cursor: pointer;
      color: #0994ff;
    }
  
    .view {
      cursor: pointer;
      color: #707070;
    }
  }
  
  .codingDataGrid {
    height: calc(100vh - 120px);
    width: 100%;
  
    .MuiDataGrid-root .MuiDataGrid-container--top [role="row"],
    .MuiDataGrid-root .MuiDataGrid-container--bottom [role="row"] {
      background: #eaf0f2;
    }
  }
  
  .datatableGrid {
    height: calc(100vh - 120px);
    width: 100%;
  
    .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.MuiDataGrid-columnHeaders {
      background: #eaf0f2 !important;
      font-size: 14px;
      font-weight: 600;
    }
  
    .MuiDataGrid-root .MuiDataGrid-container--top [role="row"],
    .MuiDataGrid-root .MuiDataGrid-container--bottom [role="row"] {
      background: #eaf0f2;
    }
  
    button {
      background: transparent;
      // padding: 4px 8px;
      border: 0;
      margin-right: 5px;
      color: #707070;
      font-size: 24px;
  
      img {
        background: none;
        border: 0;
        width: 24px;
        margin-right: 10px;
      }
    }
  
    .btn-primary {
      font-size: 12px;
      background: $white;
      border: solid 1px $text-btn;
      border-radius: 60px;
      color: $text-btn;
      margin-right: 0;
      padding: 4px 10px;
  
      &:hover {
        background: $text-btn;
        border: solid 1px $text-btn;
        color: #ffffff;
        box-shadow: none;
        padding: 4px 10px;
        transform: translateY(0px);
      }
    }
  
    .disabled {
      font-size: 12px;
      background: none;
      border: solid 1px #898989;
      border-radius: 60px;
      color: #898989;
      margin-right: 0;
      padding: 6px 12px;
    }
  }
  
  .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.MuiDataGrid-columnHeaders {
    background: #eaf0f2 !important;
    font-size: 14px;
    font-weight: 600;
  }
  
  .MuiButtonBase-root-MuiCheckbox-root {
    padding: 0;
  }
  
  .MuiDataGrid-columnHeaders {
    background: #eaf0f2 !important;
    font-size: 14px;
    font-weight: 600;
  }
  
/* responsive media quires */
// @media screen and (min-width: 1601px) and (max-width: 1920px) {
//     .form-check-input {
//       width: 18px;
//       height: 18px;
//     }
//   }