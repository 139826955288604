@font-face {
  font-family: 'icomoon';
  src:  url('icomoon.eot?bbhyq4');
  src:  url('icomoon.eot?bbhyq4#iefix') format('embedded-opentype'),
    url('icomoon.ttf?bbhyq4') format('truetype'),
    url('icomoon.woff?bbhyq4') format('woff'),
    url('icomoon.svg?bbhyq4#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-download:before {
  content: "\e95b";
 }
.icon-alertinfo:before {
  content: "\e955";
}
.icon-alertinfosolid:before {
  content: "\e956";
}
.icon-ordering:before {
  content: "\e957";
}
.icon-nowbas:before {
  content: "\e958";
}
.icon-financial-instrument:before {
  content: "\e959";
}
.icon-nowbas1:before {
  content: "\e95a";
}
.icon-split:before {
  content: "\e947";
}
.icon-itimentries:before {
  content: "\e951";
  color: #11ac11;
}
.icon-supportentries:before {
  content: "\e952";
  color: #e21515;
}
.icon-verifyentries:before {
  content: "\e953";
}
.icon-codedentries:before {
  content: "\e954";
  color: #609ee6;
}
.icon-info:before {
  content: "\e950";
 }
.icon-itim:before {
  content: "\e900";
 }
.icon-abn:before {
  content: "\e901";
  color: #e21515;
}
.icon-aboutus:before {
  content: "\e902";
 }
.icon-adduser:before {
  content: "\e903";
}
.icon-arrowdonwf:before {
  content: "\e904";
 }
.icon-arrowupf:before {
  content: "\e905";
 }
.icon-assetsentry:before {
  content: "\e906";
 }
.icon-attached:before {
  content: "\e907";
 }
.icon-attached-1:before {
  content: "\e908";
 }
.icon-attachedc:before {
  content: "\e909";
 }
.icon-bankaccount:before {
  content: "\e90a";
 }
.icon-bell:before {
  content: "\e90b";
 }
.icon-bell-1:before {
  content: "\e90c";
 }
.icon-businesscode:before {
  content: "\e90d";
 }
.icon-calender:before {
  content: "\e90e";
 }
.icon-chat:before {
  content: "\e90f";
 }
.icon-closed:before {
  content: "\e910";
}
.icon-coding:before {
  content: "\e911";
 }
.icon-companies:before {
  content: "\e912";
 }
.icon-company:before {
  content: "\e913";
 }
.icon-companydetail:before {
  content: "\e914";
 }
.icon-companydetails:before {
  content: "\e915";
 }
.icon-cross:before {
  content: "\e916";
 }
.icon-definition:before {
  content: "\e94d";
 }
.icon-delete:before {
  content: "\e917";
 }
.icon-deleteIcon:before {
  content: "\e918";
 }
.icon-downarrow:before {
  content: "\e919";
 }
.icon-edit:before {
  content: "\e91a";
 }
.icon-editIcon:before {
  content: "\e91b";
 }
.icon-eye:before {
  content: "\e91c";
}
.icon-eyeIcon:before {
  content: "\e91d";
 }
.icon-eyeoff:before {
  content: "\e91e";
 }
.icon-eyeoff-1:before {
  content: "\e91f";
 }
.icon-gstnoraml:before {
  content: "\e920";
  color: #ff9801;
}
.icon-help:before {
  content: "\e921";
 }
.icon-highfrequency:before {
  content: "\e922";
 }
.icon-inviteafrid:before {
  content: "\e923";
}
.icon-invite:before {
  content: "\e924";
 }
.icon-invites:before {
  content: "\e925";
 }
.icon-itim1 .path1:before {
  content: "\e926";
  color: rgb(17, 172, 17);
}
.icon-itim1 .path2:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(109, 113, 119);
}
.icon-itim1 .path3:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(109, 113, 119);
}
.icon-itim1 .path4:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(109, 113, 119);
}
.icon-itim1 .path5:before {
  content: "\e92a";
  margin-left: -1em;
  color: rgb(109, 113, 119);
}
.icon-itim1 .path6:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(109, 113, 119);
}
.icon-itim1 .path7:before {
  content: "\e92c";
  margin-left: -1em;
  color: rgb(109, 113, 119);
}
.icon-itim1 .path8:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-itim1 .path9:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(109, 113, 119);
}
.icon-list:before {
  content: "\e92f";
 }
.icon-menu:before {
  content: "\e930";
 }
.icon-menubar .path1:before {
  content: "\e931";
  color: rgb(96, 158, 230);
}
.icon-menubar .path2:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(226, 21, 21);
}
.icon-menubar .path3:before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(42, 42, 57);
}
.icon-menubar .path4:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(17, 172, 17);
}
.icon-moneyin:before {
  content: "\e935";
  color: #11AC11;
  padding-left: 6px;
 }
.icon-moneyout:before {
  content: "\e936";
  color: #E21515;
  padding-left: 6px;
 }
.icon-more:before {
  content: "\e937";
 }
.icon-plus:before {
  content: "\e938";
 }
.icon-portfolio:before {
  content: "\e939";
 }
.icon-privacypolicy:before {
  content: "\e93a";
 }
.icon-raised:before {
  content: "\e93b";
  color: #ffa621;
}
.icon-receipt:before {
  content: "\e93c";
 }
.icon-reopen:before {
  content: "\e93d";
  color: #1e91cf;
}
.icon-report:before {
  content: "\e93e";
 }
.icon-Resolved:before {
  content: "\e93f";
  color: #11ac11;
}
.icon-settings:before {
  content: "\e940";
 }
.icon-standingjournal:before {
  content: "\e941";
 }
.icon-supporttickets:before {
  content: "\e942";
 }
.icon-terms:before {
  content: "\e943";
 }
.icon-termsconditions:before {
  content: "\e944";
 }
.icon-toraise:before {
  content: "\e945";
  color: #e21515;
}
.icon-underverification:before {
  content: "\e946";
  color: #ffa621;
}
.icon-uparrow:before {
  content: "\e948";
 }
.icon-uploadbanktransition:before {
  content: "\e949";
 }
.icon-uploadIcon:before {
  content: "\e94a";
 }
.icon-user:before {
  content: "\e94b";
}
.icon-verified:before {
  content: "\e94c";
  color: #11ac11;
}
.icon-Verifiyit:before {
  content: "\e94e";
}
.icon-verifycoding:before {
  content: "\e94f";
 }
