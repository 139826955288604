.wrapper {
  width: 100%;
  @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
  will-change: auto;
  @include transition(padding .15s);
}
// .wrapper.sidebar-open .content-container {
//   margin-left: 256px; /* Adjust the value as needed */
//   transition: margin-left 0.3s ease;
// }

// .wrapper.sidebar-closed .content-container {
//   margin-left: 0;
//   transition: margin-left 0.3s ease;
// }
